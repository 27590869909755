@mixin background-state($background, $border, $color) {
  background-color: $background;
  color: $color;
  border-color: $border;
}

@mixin box-modal($margin, $padding, $font-size, $height, $width) {
  margin: $margin;
  padding: $padding;
  font-size: $font-size;
  height: $height;
  width: $width;
}

@mixin background-varient($background, $border, $color, $background-hover, $border-hover, $color-hover) {
  @include background-state($background, $border, $color);
  @include hover-focus {
    @include background-state($background-hover, $border-hover, $color-hover);
  }
}

@mixin hover-focus {
  &:hover,
  &:focus {
    @content;
  }
}

@mixin text-variant($parent, $color) {
  #{$parent} {
    color: $color;
  }
}

@mixin text-hover-variant($parent, $color) {
  a#{$parent}, button#{$parent}, [role='button']#{$parent} {
    background-color: transparent;
    border: none;
    text-decoration: none;
    cursor: pointer;
    @include hover-focus {
      color: $color;
    }
  }
}
