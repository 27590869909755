@import "../utils/variables/components/panel-header";

.fe-panel-header {
  background: $fe-color__background1--alpha;
  display: flex;
  height: $fe-panel-header--height;
  font-size: 12px;
  line-height: 16px;
  box-shadow: 2px 2px 4px $fe-color__box-shadow;

  &--no-background {
    background: transparent;
    box-shadow: none;
  }

  &__options {
    align-self: center;
    margin-left: auto;
    line-height: 16px;
  }
}
