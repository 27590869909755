/* KEYFRAME ANIMATIONS */
@keyframes slideInTopModal {
  from {
    margin-top: -75px;
    opacity: .2;
  }
  to {
    margin-top: 0;
    opacity: 1;
  }
}

@keyframes fadeInOverlayMask {
  from {
    background: none;
  }
  to {
    background: $fe-color__overlay-mask;
  }
}
