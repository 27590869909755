.fe-container {
  position: relative;
  display: flex;
}

.fe-main-content {
  padding: 15px;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.invisible {
  visibility: hidden;
}

.text-ellipses {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.clearfix {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.zi-1 {
  z-index: 1;
}

//Text Colors
$text-colors: (
  "primary": $fe-color__action-primary--alpha,
  "secondary": $fe-color__action-secondary--alpha,
  "danger": $fe-color__theme-danger--alpha,
  "success": $fe-color__theme-success--alpha,
  "info": $fe-color__theme-information--alpha,
  "caution": $fe-color__theme-caution--alpha,
  "warning": $fe-color__theme-warn--alpha
);

$text-hover-colors: (
  "primary": $fe-color__action-primary--beta,
  "secondary": $fe-color__action-secondary--beta,
  "danger": $fe-color__theme-danger--beta,
  "success": $fe-color__theme-success--beta,
  "info": $fe-color__theme-information--beta,
  "caution": $fe-color__theme-caution--beta,
  "warning": $fe-color__theme-warn--beta
);

@each $color, $value in $text-colors {
  @include text-variant(".text-#{$color}", $value);
}

@each $color, $value in $text-hover-colors {
  @include text-hover-variant(".text-#{$color}", $value);
}

.margin-0 {
  margin: 0;
}

.margin-4 {
  margin: 4px;
}

.margin-8 {
  margin: 8px;
}

.margin-16 {
  margin: 16px;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-top-4 {
  margin-top: 4px;
}

.margin-top-8 {
  margin-top: 8px;
}

.margin-top-16 {
  margin-top: 16px;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-bottom-4 {
  margin-bottom: 4px;
}

.margin-bottom-8 {
  margin-bottom: 8px;
}

.margin-bottom-16 {
  margin-bottom: 16px;
}

.margin-left-0 {
  margin-left: 0;
}

.margin-left-4 {
  margin-left: 4px;
}

.margin-left-8 {
  margin-left: 8px;
}

.margin-left-16 {
  margin-left: 16px;
}

.margin-right-0 {
  margin-right: 0;
}

.margin-right-4 {
  margin-right: 4px;
}

.margin-right-8 {
  margin-right: 8px;
}

.margin-right-16 {
  margin-right: 16px;
}

.padding-0 {
  padding: 0;
}

.padding-4 {
  padding: 4px;
}

.padding-8 {
  padding: 8px;
}

.padding-16 {
  padding: 16px;
}

.padding-top-0 {
  padding-top: 0;
}

.padding-top-4 {
  padding-top: 4px;
}

.padding-top-8 {
  padding-top: 8px;
}

.padding-top-16 {
  padding-top: 16px;
}

.padding-bottom-0 {
  padding-bottom: 0;
}

.padding-bottom-4 {
  padding-bottom: 4px;
}

.padding-bottom-8 {
  padding-bottom: 8px;
}

.padding-bottom-16 {
  padding-bottom: 16px;
}

.padding-left-0 {
  padding-left: 0;
}

.padding-left-4 {
  padding-left: 4px;
}

.padding-left-8 {
  padding-left: 8px;
}

.padding-left-16 {
  padding-left: 16px;
}

.padding-right-0 {
  padding-right: 0;
}

.padding-right-4 {
  padding-right: 4px;
}

.padding-right-8 {
  padding-right: 8px;
}

.padding-right-16 {
  padding-right: 16px;
}
