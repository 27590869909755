.fe-hero {
  padding: 16px;
  &__head {
    display: flex;
    margin: 16px 0;
  }
  &__head-summary {
    flex-grow: 1;
    > * {
      margin-right: 16px;
    }
  }
  &__head-actions {
    > * {
      margin-left: 16px;
    }
  }
  &__title {
    margin: 16px 0;
    display: flex;
    align-items: center;
  }
  &__title-text {
    margin: 0;
    display: inline-flex;
    margin-right: 8px;
  }
}
