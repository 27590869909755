.fe-tabs {
  &--vertical, &--horizontal {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    &--with-bg {
      background-color: $fe-tabs__item-container__background;
    }
  }
  &__item {
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    cursor: pointer;
    padding: 16px;
    font-size: 12px;
    line-height: 16px;
    color: $fe-tabs__item__color;
    position: relative;
    &:hover, &:focus {
      background-color: $fe-tabs__item-container__background--hover;
      color: $fe-tabs__item__color--hover;
      outline: 0;
    }
    &--active, &:active {
      background-color: $fe-tabs__item-container__background--active;
      color: $fe-tabs__item__color--active;
    }
    &[disabled], &.disabled {
      &,
      &:focus,
      &:hover {
        cursor: not-allowed;
        color: $fe-color__text-primary--gamma;
        background-color: transparent;
        outline: 0;
      }
    }
  }
  &__item-left {
    > * {
      margin-right: 8px;
    }
  }
  &__item-right {
    > * {
      margin-left: 8px;
    }
  }
  &--horizontal {
    &::after {
      content: '';
      border-bottom: 1px solid $fe-color__border--alpha;
      flex-grow: 1;
    }
    .fe-tabs__item {
      margin-right: 2px;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        background-color: $fe-color__action-secondary--alpha;
      }
      &:hover, &:focus {
        &::after {
          height: 2px;
          background-color: $fe-tabs__item__line--hover;
        }
      }
      &--active, &:active {
        &::after {
          height: 2px;
          background-color: $fe-tabs__item__color--active;
        }
      }
      &[disabled], &.disabled {
        &::after {
          height: 1px;
          background-color: $fe-tabs__item__line--disabled;
        }
      }
    }
  }

  &--vertical {
    min-width: 150px;
    flex-direction: column;
    display: inline-block;

    .fe-tabs__item {
      margin-bottom: 2px;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 1px;
        background-color: $fe-color__action-secondary--alpha;
      }
      &:hover, &:focus {
        &::before {
          width: 2px;
          background-color: $fe-tabs__item__line--hover;
        }
      }
      &--active, &:active {
        &::before {
          width: 2px;
          background-color: $fe-tabs__item__color--active;
        }
      }
      &[disabled], &.disabled {
        &::before {
          width: 1px;
          background-color: $fe-tabs__item__line--disabled;
        }
      }
    }
  }
}

.fe-tab-group {
  display: flex;
  &__content-container {
    padding: 16px;
  }
  &__content {
    display: none;
    &--active {
      display: block;
    }
  }

  &--vertical {
    flex-direction: row;

    .fe-tab-group__content-container {
      flex-grow: 1;
    }
  }
  &--horizontal {
    flex-direction: column;
    flex-grow: 1;
  }
}
