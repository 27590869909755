@import "../utils/variables/components/top-nav";

.fe-top-nav {
  display: flex;
  position: relative;
  &__action-item, &__action-item .fe-btn {
    font-size: 12px;
    padding: 0;
    color: $fe-color__action-secondary--alpha;
    &:hover, &:focus {
      color: $fe-color__text-primary--beta;
      background: none;
    }
  }
  &__bookend {
    position: relative;
    box-shadow: 0 0.5px 5px 0 $fe-color__box-shadow;
    background: $fe-color__background-gradient--beta;
    display: flex;
    min-width: 70px;
    &:before {
      content: '';
      display: block;
      height: 57px;
      width: 57px;
      position: absolute;
      top: 0;
      background: $fe-color__background-gradient--beta;
      box-shadow: 3px 0.5px 3px -2px $fe-color__box-shadow;
    }
    &--left {
      border-right: 0;
      &:before{
        right: -28px;
        transform: skewX(-45deg);
      }
    }
    &--right {
      flex-direction: row;
      align-items: center;
      padding: 16px 24px;
      border-left: 0;
      &:before{
        left: -28px;
        transform: skewX(45deg);
        box-shadow: -3px 0.5px 3px -2px $fe-color__box-shadow;
      }
    }
  }
  &__bar {
    height: $fe-top-nav__header-height--topbar;

    &-nav {
      list-style: none;
      margin: 0;
      padding: 0;
      &-items {
        display: inline-block;
        margin-right: 8px;
        &:first-child {
          margin-left: 16px;
        }
      }
    }
  }
  &__home {
    text-decoration: none;
    display: flex;
    align-items: center;
    z-index: 1;
    position: relative;
  }
  &__title {
    padding: 16px;
  }
  &__logo {
    background-image: $fe-trinity-logo;
    height: 20px;
    width: 20px;
    margin-right: 8px;
  }

  &__product {
    font-family: "Gotham Book";
    font-size: 16px;
    text-transform: uppercase;
    color: $fe-color__text-primary--alpha;
  }

  &__action-container {
    margin-left: auto;

    & > * {
      display: inline-block;
      margin: 0 12px;
      white-space: nowrap;
    }
  }
  &__topnav {
    .fe-top-nav__bar {
      flex: 1 1 auto;
      box-shadow: 0 0.5px 5px 0 $fe-color__box-shadow;
      background-color: $fe-top-nav-bar-color;
      padding-left: 59px;
    }
  }
  &__topbar {
    width: 100%;
    height: $fe-top-nav__header-height--topbar;
    background: $fe-color__background-gradient--beta;
    .fe-top-nav__bar {
      margin: 0 auto;
    }
    .fe-top-nav__action-container {
      padding: 12px;
    }
  }
  .fe-product {
    bottom: 0;
  }
}
