.fe-breadcrumb {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  &__item {
    display: flex;
    align-items: baseline;
    &+ .fe-breadcrumb__item {
      font-weight: bold;
      &::before {
        @include fa-icon;
        @extend .far;
        content: fa-content($fa-var-long-arrow-right);
        color: $fe-color__action-secondary--gamma;
        font-size: 12px;
        line-height: 16px;
      }
    }
    .fe-btn--link.active {
      background-color: transparent;
      color: $fe-color__active--beta;
    }
  }
}
