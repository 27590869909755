@mixin product-differentiation($parent, $colors) {
  #{$parent} {
    background-image: linear-gradient(-90deg, #{nth($colors, 1);}, #{nth($colors, 2);});
    width: 100%;
    height: 3px;
    position: absolute;
    right: 0;
    left: 0;
    &::after {
      content: '';
      border-top: 3px solid #{nth($colors, 1);};
      border-right: 3px solid transparent;
      position: absolute;
      right: -3px;
      display: block;
      height: 0;
      width: 0;
    }
  }
}
