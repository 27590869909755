.fe-spinner {
  position: relative;

  &__circle {
    position: absolute;
    top: 0;
    left: 0;
  }
  .fa-circle {
    color: $fe-spinner-circle;
  }
  .fa-spinner-third {
    color: $fe-spinner;
  }
  &--sm {
    height: 16px;
    width: 16px;
    font-size: 12px;
    .far {
      line-height: 15px;
    }
  }
  &--l {
    height: 16px;
    width: 16px;
    font-size: 16px;
    .far {
      line-height: 16px;
    }
  }
  &--xl {
    height: 32px;
    width: 32px;
    font-size: 24px;
    .far {
      line-height: 32px;
    }
  }
  &--xxl {
    height: 64px;
    width: 64px;
    font-size: 64px;
    .far {
      line-height: 64px;
    }
  }
}

.fe-stacked-spinner {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  .caption {
    margin-top: 8px;
  }
}

.fe-compact-spinner {
  display: inline-flex;
  align-items: center;
  .caption {
    margin-left: 8px;
  }
}
