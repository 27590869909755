@import "../utils/variables/components/button";

.fe-btn {
  position: relative;
  display: inline-block;
  font-weight: bold;
  text-decoration: none;
  letter-spacing: 0px;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  outline: 0;

  &--xs {
    line-height: $fe-btn--xs-height;
    @include box-modal($fe-btn--xs-margin, $fe-btn--xs-padding, $fe-btn--xs-font-size, $fe-btn--xs-height, $fe-btn--xs-width);
  }

  &--sm {
    line-height: $fe-btn--sm-height;
    @include box-modal($fe-btn--sm-margin, $fe-btn--sm-padding, $fe-btn--sm-font-size, $fe-btn--sm-height, $fe-btn--sm-width);
  }
  &--md {
    line-height: $fe-btn--md-height;
    @include box-modal($fe-btn--md-margin, $fe-btn--md-padding, $fe-btn--md-font-size, $fe-btn--md-height, $fe-btn--md-width);
  }
  &--lg {
    line-height: $fe-btn--lg-height;
    @include box-modal($fe-btn--lg-margin, $fe-btn--lg-padding, $fe-btn--lg-font-size, $fe-btn--lg-height, $fe-btn--lg-width);
  }

  &--primary {
    @include background-varient($fe-btn--primary--main, $fe-btn--primary-border--main, $fe-btn--primary-text--main,
                                $fe-btn--primary--hover, $fe-btn--primary-border--hover, $fe-btn--primary-text--hover);
    &.active,
    &:active {
      @include background-state($fe-btn--primary--active, $fe-btn--primary-border--active, $fe-btn--primary-text--active);
      [class^="fe-icon"] {
        font-weight: 600;
      }
    }
    &.disabled,
    &[disabled] {
      cursor: not-allowed;
      @include background-state($fe-btn--primary--disabled, $fe-btn--primary-border--disabled, $fe-btn--primary-text--disabled);
    }
  }

  &--primary-danger {
    @include background-varient($fe-btn--primary-danger--main, $fe-btn--primary-danger-border--main, $fe-btn--primary-danger-text--main,
                                $fe-btn--primary-danger--hover, $fe-btn--primary-danger-border--hover, $fe-btn--primary-danger-text--hover);
    &.active,
    &:active {
      @include background-state($fe-btn--primary-danger--active, $fe-btn--primary-danger-border--active, $fe-btn--primary-danger-text--active);
      [class^="fe-icon"] {
        font-weight: 600;
      }
    }
    &.disabled,
    &[disabled] {
      cursor: not-allowed;
      @include background-state($fe-btn--primary--disabled, $fe-btn--primary-border--disabled, $fe-btn--primary-text--disabled);
    }
  }

  &--secondary {
    @include background-varient($fe-btn--secondary--main, $fe-btn--secondary-border--main, $fe-btn--secondary-text--main,
                                $fe-btn--secondary--hover, $fe-btn--secondary-border--hover, $fe-btn--secondary-text--hover);
    &.active,
    &:active {
      @include background-state($fe-btn--secondary--active, $fe-btn--secondary-border--active, $fe-btn--secondary-text--active);
      [class^="fe-icon"] {
        font-weight: 600;
      }
    }
    &.disabled,
    &[disabled] {
      cursor: not-allowed;
      @include background-state($fe-btn--secondary--disabled, $fe-btn--secondary-border--disabled, $fe-btn--secondary-text--disabled);
    }
  }

  &--secondary-danger {
    @include background-varient($fe-btn--secondary-danger--main, $fe-btn--secondary-danger-border--main, $fe-btn--secondary-danger-text--main,
                                $fe-btn--secondary-danger--hover, $fe-btn--secondary-danger-border--hover, $fe-btn--secondary-danger-text--hover);
    &.active,
    &:active {
      @include background-state($fe-btn--secondary-danger--active, $fe-btn--secondary-danger-border--active, $fe-btn--secondary-danger-text--active);
      [class^="fe-icon"] {
        font-weight: 600;
      }
    }
    &.disabled,
    &[disabled] {
      cursor: not-allowed;
      @include background-state($fe-btn--secondary--disabled, $fe-btn--secondary-border--disabled, $fe-btn--secondary-text--disabled);
    }
  }

  &--link {
    @include background-varient($fe-btn--link--main, $fe-btn--link-border--main, $fe-btn--link-text--main,
                                $fe-btn--link--hover, $fe-btn--link-border--hover, $fe-btn--link-text--hover);
    &:active {
      @include background-state($fe-btn--link--active, $fe-btn--link-border--active, $fe-btn--link-text--active);
      [class^="fe-icon"] {
        font-weight: 600;
      }
    }
    &.disabled,
    &[disabled] {
      cursor: not-allowed;
      @include background-state($fe-btn--link--disabled, $fe-btn--link-border--disabled, $fe-btn--link-text--disabled);
    }
    &.active-nav {
      @include background-state($fe-btn--link-active-nav--main, $fe-btn--link-active-nav-border--main, $fe-btn--link-active-nav-text--main);
      &:before {
       content: "";
       height: 2px;
       width: 100%;
       position: absolute;
       bottom: -1px;
       left: 0;
       background: $fe-btn--link-active-nav-text--main;
      }
      [class^="fe-icon"] {
        font-weight: 600;
      }
    }
    &.active {
      @include background-state($fe-btn--link--active, $fe-btn--link-border--active, $fe-btn--link-text--active-btn);
      [class^="fe-icon"] {
        font-weight: 600;
      }
    }
  }

  &--link-danger {
    @include background-varient($fe-btn--link-danger--main, $fe-btn--link-danger-border--main, $fe-btn--link-danger-text--main,
                                $fe-btn--link-danger--hover, $fe-btn--link-danger-border--hover, $fe-btn--link-danger-text--hover);
    &.active,
    &:active {
      @include background-state($fe-btn--link-danger--active, $fe-btn--link-danger-border--active, $fe-btn--link-danger-text--active);
      [class^="fe-icon"] {
        font-weight: 600;
      }
    }
    &.disabled,
    &[disabled] {
      cursor: not-allowed;
      @include background-state($fe-btn--link--disabled, $fe-btn--link-border--disabled, $fe-btn--link-text--disabled);
    }
  }
  &--link-light {
    @include background-varient($fe-btn--link-light--main, $fe-btn--link-light-border--main, $fe-btn--link-light-text--main,
                                $fe-btn--link-light--hover, $fe-btn--link-light-border--hover, $fe-btn--link-light-text--hover);
    &.active,
    &:active {
      @include background-state($fe-btn--link-light--active, $fe-btn--link-light-border--active, $fe-btn--link-light-text--active);
      [class^="fe-icon"] {
        font-weight: 600;
      }
    }
    &.disabled,
    &[disabled] {
      cursor: not-allowed;
      @include background-state($fe-btn--link-light--disabled, $fe-btn--link-light-border--disabled, $fe-btn--link-light-text--disabled);
    }
  }
}
