@import "../utils/variables/components/radio";

.fe-radio {
  position: relative;
  display: flex;
  align-items: center;

  &--inline {
    display: inline-flex;
  }

  &__overlay {
    position: absolute;
    width: $fe-radio__overlay-height;
    height: $fe-radio__overlay-height;
    left: -($fe-radio__overlay-height - $fe-radio__radio-height)/2;
    top: -($fe-radio__overlay-height - $fe-radio__radio-height)/2;
    opacity: .2;
    border-radius: 50%;
  }

  &__radio {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: $fe-radio__radio-height;
    height: $fe-radio__radio-height;
    border-radius: 50%;
    background: $fe-radio-button__background--main;
    border: $fe-radio__radio-border-width solid $fe-radio-button__border--main;

    &:before {
      position: absolute;
      left: $fe-radio__radio-height/4 - $fe-radio__radio-border-width;
      top: $fe-radio__radio-height/4 - $fe-radio__radio-border-width;
      display: inline-block;
      width: $fe-radio__radio-height/2;
      height: $fe-radio__radio-height/2;
      border-radius: 50%;
    }
  }

  &__label {
    margin: $fe-radio__label-margin;
    line-height: 16px;
    font-size: 12px;
    font-weight: bold;
    color: $fe-color__text-primary--alpha;
  }

  &__input {
    opacity: 0;
    z-index: $fe-radio__input-z-index;
    width: $fe-radio__radio-height;
    height: $fe-radio__radio-height;
    &:checked ~ .fe-component-wrapper .fe-radio__radio,
    &:checked ~ .fe-radio__radio {
      border: $fe-radio__radio-border-width solid $fe-radio-button__border--selected;
      background: $fe-radio-button__background--selected;
      &:before {
        content: '';
        background: $fe-radio-button__fill;
      }
    }

    &:hover ~ .fe-component-wrapper .fe-radio__radio,
    &:focus ~ .fe-component-wrapper .fe-radio__radio,
    &:hover ~ .fe-radio__radio,
    &:focus ~ .fe-radio__radio {
      border: $fe-radio__radio-border-width solid $fe-radio-button__border--main;
      background: $fe-radio-button__background--hover;
    }

    &:hover ~ .fe-radio__label,
    &:focus ~ .fe-radio__label {
      color: $fe-radio-button__label--hover;
    }

    &:hover ~ .fe-component-wrapper .fe-radio__overlay,
    &:focus ~ .fe-component-wrapper .fe-radio__overlay,
    &:hover ~ .fe-radio__overlay,
    &:focus ~ .fe-radio__overlay {
      background: $fe-radio-button__fill;
    }

    &:focus ~ .fe-component-wrapper .fe-radio__radio,
    &:focus ~ .fe-radio__radio {
      &:before {
        content: '';
        background: $fe-radio-button__fill;
      }
    }

    &.disabled,
    &[disabled] {
      cursor: not-allowed;

      &~ .fe-component-wrapper .fe-radio__radio,
      &~ .fe-radio__radio {
        border: $fe-radio__radio-border-width solid $fe-radio-button__border--disabled;
        background: $fe-radio-button__background--disabled;
      }

      &~ .fe-component-wrapper .fe-radio__overlay,
      &~ .fe-radio__overlay {
        display: none;
      }
    }
  }
}
