.fe-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  &__blip {
    height: 8px;
    width: 8px;
    margin-right: 8px;
    border-radius: 50%;
    background-color: $fe-pagination__blip-background;
    position: relative;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    &.active {
      background-color: transparent;
      border: 1px solid $fe-pagination__blip--active;
    }
  }
  &__rows {
    flex-grow: 1;
    color: $fe-color__text-primary--alpha;
  }
  &__pages {
    display: flex;
    align-items: center;
    color: $fe-color__text-primary--alpha;
  }
  &__pages-filter-label {
    color: $fe-color__text-primary--gamma;
  }
  &__label {
    margin-right: 8px;
  }
  .fe-form__input-wrapper {
    width: 80px;
    margin: 0 8px;
  }
  input[type=number] {
      -moz-appearance:textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
      }
  }
}
