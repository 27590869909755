$fe-color__transparent: unquote('#0000');
//Actions Primary  {main, bright, dark}
$fe-color__action-primary--alpha: #0fb8dc;
$fe-color__action-primary--beta: #74c5e0;
$fe-color__action-primary--gamma: #4192ad;

//Actions Secondary  {main, bright, dark}
$fe-color__action-secondary--alpha: #bebebe;
$fe-color__action-secondary--beta: #f1f1f1;
$fe-color__action-secondary--gamma: rgba(190, 190, 190, 0.5); // #bebebe at 50%

//Backgrounds 1 {main, bright, dark}
$fe-color__background1--alpha: #0d1a2b;
$fe-color__background1--beta: #222222;
$fe-color__background1--gamma: rgba(0, 0, 0, 0.4);

//Backgrounds 2 {main, bright, dark}
$fe-color__background2--alpha: rgba(255, 255, 255, 0.2); // #ffffff at 20%
$fe-color__background2--beta: #f9f9f9;
$fe-color__background2--gamma: rgba(255, 255, 255, 0.1); // #ffffff at 10%

// Active
$fe-color__active--alpha: #fb715e;
$fe-color__active--beta: #f5f5f5;
$fe-color__active--gamma: #000000;

//Background gradient
$fe-color__background-gradient--alpha: linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, #111111 100%);
$fe-color__background-gradient--beta: linear-gradient(180deg, #4A71A0 0%, #262636 100%);
$fe-color__background-gradient--gamma: radial-gradient(circle at 50% top, #355881 0%, #091B31 900px);

//Box box-shadow
$fe-color__box-shadow: rgba(0, 0, 0, 0.25);
$fe-color__overlay-box-shadow: rgba(0, 0, 0, 0.55);

//Modal Overlay Mask
$fe-color__overlay-mask: rgba(0, 0, 0, .4);

//Borders/Lines  {main, bright, dark}
$fe-color__border--alpha: #777777;
$fe-color__border--beta: #e1e1e1;
$fe-color__border--gamma: #151515;

//Themes {main, bright, dark}
$fe-color__theme-success--alpha: #59dc90;
$fe-color__theme-success--beta: #8fffc1;
$fe-color__theme-success--gamma: #11a962;

$fe-color__theme-information--alpha: #99ddff;
$fe-color__theme-information--beta: #cdffff;
$fe-color__theme-information--gamma: #66abcc;

$fe-color__theme-caution--alpha: #ffe352;
$fe-color__theme-caution--beta: #ffeb85;
$fe-color__theme-caution--gamma: #e0b400;

$fe-color__theme-warn--alpha: #f0950e;
$fe-color__theme-warn--beta: #f09f54;
$fe-color__theme-warn--gamma: #c97300;

$fe-color__theme-danger--alpha: #ea475b;
$fe-color__theme-danger--beta: #ff7b88;
$fe-color__theme-danger--gamma: #b20032;

$fe-color__theme-intermediate--alpha: #00fff0;
$fe-color__theme-intermediate--beta: #70ffff;
$fe-color__theme-intermediate--gamma: #00cbbe;

$fe-color__theme-benign--alpha: #d7d7d7;
$fe-color__theme-benign--beta: #f2f2f2;
$fe-color__theme-benign--gamma: #aaaaaa;

//Texts {main 90%, bright 100%, dark 40%}
$fe-color__text-primary--alpha: rgba(255, 255, 255, 0.9);
$fe-color__text-primary--beta: #ffffff;
$fe-color__text-primary--gamma: rgba(255, 255, 255, 0.4);

$fe-color__text-secondary--alpha: rgba(0, 0, 0, 0.7);
$fe-color__text-secondary--beta: rgba(0, 0, 0, 0.9);
$fe-color__text-secondary--gamma: rgba(0, 0, 0, 0.3);

//Product Colors
$fe-color__brand--alpha-0: #724cfc;
$fe-color__brand--alpha-100: #1b8d96;
$fe-color__brand--beta-0: #2e5799;
$fe-color__brand--beta-100: #0fb8dc;
$fe-color__brand--gamma-0: #e843e2;
$fe-color__brand--gamma-100: #b2282d;
$fe-color__brand--delta-0: #724cfc;
$fe-color__brand--delta-100: #1b8d96;
$fe-color__brand--epsilon-0: #c0ff2d;
$fe-color__brand--epsilon-100: #6a823a;
$fe-color__brand--zeta-0: #f99b1c;
$fe-color__brand--zeta-100: #e7551c;
$fe-color__brand--eta-0: #fff517;
$fe-color__brand--eta-100: #f99b1c;
$fe-color__brand--theta-0: #0fb8dc;
$fe-color__brand--theta-100: #26d96e;
$fe-color__brand--iota-0: #1099d6;
$fe-color__brand--iota-100: #bcd2ea;
