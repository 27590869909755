//Button
$fe-btn--xs-margin: 0px;
$fe-btn--xs-padding: 0px;
$fe-btn--xs-font-size: 12px;
$fe-btn--xs-height: 16px;
$fe-btn--xs-width: auto;

$fe-btn--sm-margin: 0px;
$fe-btn--sm-padding: 0 8px;
$fe-btn--sm-font-size: 12px;
$fe-btn--sm-height: 24px;
$fe-btn--sm-width: auto;

$fe-btn--md-margin: 0px;
$fe-btn--md-padding: 0px 16px;
$fe-btn--md-font-size: 12px;
$fe-btn--md-height: 32px;
$fe-btn--md-width: auto;

$fe-btn--lg-margin: 0px;
$fe-btn--lg-padding: 0 32px;
$fe-btn--lg-font-size: 12px;
$fe-btn--lg-height: 48px;
$fe-btn--lg-width: auto;
