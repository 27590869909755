.fe-progress {
  margin: 0 16px;
  &__label {
    color: $fe-color__text-primary--alpha;
    margin: 0;
  }
  &__bar {
    margin: 8px 0;
    height: 4px;
    background-color: $fe-color__background1--gamma;
    border-radius: 2px;
    display: flex;
  }
  &__bar-level {
    background-color: $fe-color__action-primary--alpha;
    border-radius: 2px;
    transition: width .6s ease;
    &--success {
      background-color: $fe-color__theme-success--alpha;
    }
    &--warn {
      background-color: $fe-color__theme-warn--alpha;
    }
    &--error {
      background-color: $fe-color__theme-danger--alpha;
    }
  }
  &__caption {
    color: $fe-color__text-primary--gamma;
  }
}

.fe-circular-progress {
  display: inline-flex;
  align-items: center;
  &__circle {
    fill: transparent;
    cx: 50%;
    cy: 50%;
    stroke: $fe-color__background1--gamma;
  }
  &__circle-level {
    transition: 0.35s stroke-dashoffset;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    stroke: $fe-color__action-primary--alpha;
    &--success {
      stroke: $fe-color__theme-success--alpha;
    }
    &--warn {
      stroke: $fe-color__theme-warn--alpha;
    }
    &--error {
      stroke: $fe-color__theme-danger--alpha;
    }
  }
  &__circle--xxl {
    width: 64px;
    height: 64px;
    .fe-circular-progress__circle {
      r: 29;
      stroke-width: 6;
    }
  }
  &__circle--xl {
    width: 32px;
    height: 32px;
    .fe-circular-progress__circle {
      r: 14;
      stroke-width: 4;
    }
  }
  &__circle--l {
    width: 16px;
    height: 16px;
    .fe-circular-progress__circle {
      r: 7;
      stroke-width: 2;
    }
  }
  &__circle--s {
    width: 8px;
    height: 8px;
    .fe-circular-progress__circle {
      r: 3.5;
      stroke-width: 1;
    }
  }
  &--stacked {
    flex-direction: column;
    .fe-progress__caption {
      margin-top: 8px;
    }
  }
  &--compact {
    .fe-progress__caption {
      margin-left: 8px;
    }
  }
}
