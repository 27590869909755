.fe-table {
  position: relative;
  width: 100%;
  overflow: auto;

  &__header-title {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 8px;
    display: flex;
  }

  &__header-filters {
    margin-top: auto;
  }

  &__header-text {
    flex-grow: 1;
    margin-right: 8px;
    color: $fe-color__table-header-text;
  }

  &__header-cell {
    position: relative;
    background-color: $fe-color__table-header--background;
    border-top: 1px solid $fe-color__table-header--border-hr;
    border-bottom: 1px solid $fe-color__table-header--border-hr;
    border-left: 1px solid $fe-color__table-header--border-v;
    display: inline-flex;
    flex-direction: column;
    padding: 16px 16px 8px;
    min-width: 48px;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    font-size: 12px;
    line-height: 16px;

    &:last-child {
      border-right: 1px solid $fe-color__table-header--border-v;
    }

    &:hover {
      .fe-table__header-highlight {
        opacity: 1;
      }
    }

    &--selected {
      background-color: $fe-color__table-selected;

      .fe-table__header-highlight {
        opacity: 1;
      }
    }

    &--options {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 80px;
    }

    &--select {
      flex-grow: 0;
    }
  }

  &__header-highlight {
    background-image: $fe-highlight-image;
    background-repeat: round;
    height: 8px;
    opacity: 0;
    transition: opacity 0.5s ease-in;
    cursor: grab;
    position: absolute;
    top: 4px;
    left: 16px;
    right: 16px;
  }

  &__header-sort {
    position: relative;
    top: 2px;
    margin-left: 8px;
    color: $fe-color__table-header-sort;

    .fas {
      position: absolute;
      right: 0;
    }

    &--asc,
    &--desc {
      color: $fe-color__active--alpha;
    }

    &--asc {
      .fa-sort-up {
        visibility: hidden;
      }
    }

    &--desc {
      .fa-sort-down {
        visibility: hidden;
      }
    }
  }

  &__cell {
    position: relative;
    display: inline-flex;
    background: transparent;
    padding: 16px;
    border-bottom: 1px solid $fe-color__border--alpha;
    border-top: 1px solid transparent;
    border-left: 1px solid $fe-color__table-cell--border-v;
    min-width: 48px;
    transition: border 0.5s ease-in;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    color: $fe-color__text-primary--alpha;
    font-size: 12px;
    line-height: 16px;

    &:last-child {
      border-right: 1px solid $fe-color__table-cell--border-v;
    }

    &--selected {
      background-color: $fe-color__table-selected;
    }

    &--options {
      background-color: $fe-color__table-header--background;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 80px;
    }

    &--select {
      flex-grow: 0;
    }
  }

  &__row {
    display: flex;

    &:hover {
      .fe-table__cell:not(.fe-table__cell--options):not(.fe-table__cell--selected) {
        background: $fe-color__table-cell--hover;
      }

      .fe-table__cell {
        border-top-color: $fe-color__table-cell-border--hover;
        border-bottom-color: $fe-color__table-cell-border--hover;
      }
    }
  }

  &--sticky-options {
    .fe-table__row {
      position: relative;
      .fe-table__cell--options, .fe-table__header-cell--options {
        position: -webkit-sticky; // for safari and webkit based browsers
        position: sticky;
        right: 0;
        top: 0;
        width: 80px;
        box-shadow: -8px 0px 4px -1px $fe-color__box-shadow;
      }
    }
  }

  &__dragged {
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: $fe-color__table-column-dragged;
    width: 200px;
    opacity: 0;
    cursor: grabbing;
  }

  &__droppable-highlight {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: $fe-color__active--alpha;
    opacity: 0;
  }
}
/*Fix for angular project*/
fe-table-cell,
fe-table-header {
  flex-grow: 1;
  display: inline-flex;
  flex-basis: 0;
  min-width: 48px;
}

fe-table-cell.fe-component-wrapper,
fe-table-header.fe-component-wrapper {
  padding: 0;
  position: static;
}

/* End of angular fix */
