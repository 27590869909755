.fe-select {
  display: block;
  width: 100%;
  &__select {
    border-radius: 0;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
  }
}
