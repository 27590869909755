@import "../utils/variables/components/label";

.fe-label {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: $fe-label--height;
  padding: $fe-label--padding;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  margin-right: $fe-label--right-margin;
  border: none;
  background: transparent;
  color: $fe-color__text-primary--alpha;
  word-break: break-all;
  text-transform: capitalize;
  &--icon {
    padding-left: $fe-label--icon--left-padding;
  }

  &__icon {
    margin-right: $fe-label__icon--right-margin;
    color: $fe-color__action-primary--alpha;
  }

  &--background {
    background: $fe-label--background;

    &::after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: $fe-label--height 0 0 $fe-label--height;
      border-color: transparent transparent transparent $fe-label--background;
      position: absolute;
      top: 0;
      right: -$fe-label--height;
    }
  }
}
