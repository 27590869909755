@import "../utils/variables/components/side-nav";

.fe-side-nav {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  background: $fe-side-nav-background;
  border-right: $fe-side-nav--border-width solid $fe-color__border--alpha;
  box-shadow: 2px 2px 4px $fe-color__box-shadow;
  height: 100vh;
  z-index: 1;
  width: 200px;

  &__header {
    // background: $fe-color__background-gradient--beta;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid $fe-color__border--alpha;
    width: 164px;
    word-wrap: break-word;
    margin: 16px auto;
    &-link {
      width: $fe-side-nav__header-link--height;
      height: $fe-side-nav__header-link--height;
      background: transparent;
      border: 0;
      font-size: $fe-side-nav__header-link--font-size;
      color: $fe-list__item-text;
      &:hover {
        color: $fe-list__item-text--hover;
      }
    }

    .fe-top-nav__title-brand {
      font-size: 11px;
      height: 11px;
      line-height: 11px;
    }
    .fe-top-nav__title-name {
      font-size: 24px;
      line-height: 24px;
      height: auto;
    }
  }

  &__nav-container {
    overflow: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__footer {
    padding: 16px 0;
    text-align: center;
    margin-top: auto;

    &--ud-section {
      margin-top: 16px;
    }
  }

  .fe-product {
    top: 0;
    &::after {
      display: none;
    }
  }
}
