$fe-color__table-header--background: $fe-color__background1--alpha;
$fe-color__table-header--border-hr: $fe-color__border--gamma;
$fe-color__table-header--border-v: $fe-color__border--gamma;
$fe-color__table-selected: $fe-color__active--gamma;
$fe-color__table-header-text: $fe-color__text-primary--beta;
$fe-color__table-header-sort: $fe-color__action-secondary--gamma;
$fe-color__table-cell--border-v: $fe-color__border--gamma;
$fe-color__table-cell--hover: $fe-color__background1--gamma;
$fe-color__table-cell-border--hover: $fe-color__border--beta;
$fe-color__table-column-dragged: $fe-color__background1--gamma;
$fe-highlight-image: url('../images/ellipse-dark.svg');
