.fe-notice {
  width: 288px;
  background-color: $fe-color__notification-background;
  border-radius: 4px;
  position: relative;
  display: flex;
  box-shadow: 0 0 10px 0 $fe-color__box-shadow;
  margin-bottom: 8px;
  &__close {
    all: unset;
    position: absolute;
    top: 4px;
    right: 4px;
    height: 16px;
    width: 16px;
    text-align: center;
    color: $fe-color__notification-close;
    cursor: pointer;
  }
  &__indicator {
    display: flex;
    align-items: center;
    padding: 2px;
    font-size: 32px;
    color: $fe-color__notification-icon;
    border-radius: 4px 0 0 4px;
    &--icon {
      padding: 8px;
    }
  }
  &__message {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 8px 16px;
  }
  &__label {
    font-size: 8px;
    font-weight: bold;
    color: $fe-color__notification-label;
    margin: 0;
    text-transform: uppercase;
    line-height: 16px;
  }
  &__text {
    margin: 0;
    font-size: 12px;
    color: $fe-color__notification-msg;
    max-height: 48px;
    overflow: hidden;
    line-height: 16px;
  }
  &__actions {
    margin: 0;
    line-height: 16px;
  }
  &--success {
    .fe-notice__indicator {
      background-color: $fe-color__theme-success--alpha;
    }
  }
  &--warning {
    .fe-notice__indicator {
      background-color: $fe-color__theme-warn--alpha;
    }
  }
  &--error {
    .fe-notice__indicator {
      background-color: $fe-color__theme-danger--alpha;
    }
  }
  &--info {
    .fe-notice__indicator {
      background-color: $fe-color__theme-information--alpha;
    }
  }
  &--auto-dismiss {
    position: relative;
    overflow: hidden;
    .fe-notice__dismiss-timer {
      width: 4px;
      position: absolute;
      left: 0;
      bottom: 0;
      top: 0;
      background-color: $fe-color__notification-dismiss-timer;
      animation-name: dismiss-timer;
      animation-timing-function: linear;
      animation-duration: 5s;
    }
  }
}

.fe-notice-container {
  opacity: 0;
  position: fixed;
  top: 16px;
  right: 16px;
  z-index: 1000;
  &--display {
    display: flex;
    flex-direction: column-reverse;
    opacity: 1;
  }
}

@keyframes dismiss-timer {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}
