.fe-text-enrich {
  background: $fe-color__background2--gamma;
  border-radius: 4px;
  outline: 0;
  border: 0;
  font-weight: bold;
  line-height: 16px;
  font-size: 12px;
  color: $fe-color__text-primary--alpha;
  padding: 0 8px;
  &:hover, &:focus, &:active {
    background: $fe-text-enrich__background--hover;
    color: $fe-color__text-primary--beta;
    box-shadow: 0px 2px 4px $fe-color__box-shadow;
  }
  &--danger, &--warning, &--info {
    border-left: 4px solid;
    padding-left: 4px;
  }
  &--danger {
    border-left-color: $fe-color__theme-danger--alpha;
    &:hover, &:focus {
      border-left-color: $fe-color__theme-danger--beta;
    }
    &:active {
      border-left-color: $fe-color__theme-danger--alpha;
    }
  }
  &--warning {
    border-left-color: $fe-color__theme-warn--alpha;
    &:hover, &:focus {
      border-left-color: $fe-color__theme-warn--beta;
    }
    &:active {
      border-left-color: $fe-color__theme-warn--alpha;
    }
  }
  &--info {
    border-left-color: $fe-color__theme-information--alpha;
    &:hover, &:focus {
      border-left-color: $fe-color__theme-information--beta;
    }
    &:active {
      border-left-color: $fe-color__theme-information--alpha;
    }
  }
}
