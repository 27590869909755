/* BEGIN Light */
@font-face {
  font-family: 'Open Sans';
  @include fontdef($OpenSansPath, $OpenSansVersion, "Light");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Source Code Pro';
  src: url("../fonts/Light/SourceCodePro-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
/* END Light */
