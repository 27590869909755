.fe-footer {
  background-color: $fe-footer__background;
  padding: 16px;
  display: flex;
  &__link-list {
    margin: 0 0 0 auto;
    list-style-type: none;
  }
  &__item {
    &,
    a {
      color: $fe-footer__text;
      display: inline-block;
      font-size: 12px;
      line-height: 16px;
      margin-right: 16px;
      text-decoration: none;
      &:last-of-type {
        margin-right: 0;
      }  
    }
  }
}
