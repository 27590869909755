.fe-input-hint-text {
  font-size: 10px;
  line-height: 16px;
  font-weight: bold;
  color: $fe-color__text-primary--gamma;
}

.fe-input {
  display: block;
  width: 100%;
  background-color: $fe-input-background;
  border: 1px solid $fe-input-border;
  margin: 2px 0;
  font-size: 12px;
  line-height: 16px;
  padding: 8px;
  box-sizing: border-box;
  color: $fe-color__text-primary--alpha;
  &::placeholder {
    font-style: italic;
    color: $fe-color__text-primary--gamma;
  }

  &:focus {
    border-color: $fe-input-border--focus;
    outline: 0;
    color: $fe-color__text-primary--beta;
  }
  &:disabled {
    background-color: $fe-input-background--disabled;
    border: 0;
    color: $fe-input-color--disabled;
    cursor: not-allowed;
    + .fe-input-hint-text {
      color: $fe-input-label-color--disabled;
    }
  }
  &--self-contained {
    margin: 0;
    padding: 4px 8px;
    border-radius: 4px;
  }
  &--light-bg {
    background-color: $fe-color__transparent;
    border-color: $fe-input-border--light;
    color: $fe-input-color--light;
    &::placeholder {
      color: $fe-input-placeholder--light;
    }
    &:focus {
      border-color: $fe-input-border--focus-light;
      color: $fe-input-color--light;
    }
    &:disabled {
      background-color: $fe-input-background--disabled-light;
      border: 0;
      color: $fe-input-color--disabled-light;
    }
  }
}

.fe-input-label {
  display: block;
  line-height: 16px;
  color: $fe-color__text-primary--alpha;
  &--focus {
    color: $fe-color__text-primary--beta;
  }
  &--disabled {
    color: $fe-input-label-color--disabled;
  }
}

.fe-input-icon {
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translate(0%, -50%);
  font-size: 12px;
  line-height: 16px;
  &--disabled {
    color: $fe-input-icon-color--disabled;
  }
  &--focus {
    color: $fe-color__active--alpha;
  }
}
