.fe-checkbox {
  &--inline {
    display: inline-block;
  }
  &__checkbox {
    font-size: 16px;
    position: relative;
    display: inline-block;
    height: 16px;
    &::before {
      @include fa-icon;
      @extend .far;
      content: fa-content($fa-var-square);
      color: $fe-checkbox__checkbox;
    }
  }
  &__text {
    font-size: 12px;
    font-weight: bold;
    color: $fe-color__text-primary--beta;
    display: inline-block;
    margin-left: 8px;
  }
  &__label {
    line-height: 16px;
    cursor: pointer;
    &:hover,
    &:focus {
      .fe-checkbox__checkbox::before {
        color: $fe-checkbox__checkbox--hover;
      }
      .fe-checkbox__checkbox::after {
        content: '';
        height: 20px;
        width: 20px;
        display: block;
        top: 0;
        left: -3px;
        position: absolute;
        background-color: $fe-checkbox__checkbox--hover-overlay;
        border-radius: 4px;
      }
    }
    &:active {
      .fe-checkbox__checkbox::before {
        color: $fe-checkbox__checkbox--active;
      }
      .fe-checkbox__checkbox::after {
        background-color: $fe-checkbox__checkbox--active-overlay;
      }
    }
  }
  &__input {
    height: 0;
    width: 0;
    opacity: 0;
    &:focus {
      + .fe-checkbox__checkbox::before {
        color: $fe-checkbox__checkbox--hover;
      }
      + .fe-checkbox__checkbox::after {
        content: '';
        height: 20px;
        width: 20px;
        display: block;
        top: 0;
        left: -3px;
        position: absolute;
        background-color: $fe-checkbox__checkbox--hover-overlay;
        border-radius: 4px;
      }
    }
    &:checked {
      + .fe-checkbox__checkbox {
        &::before {
          @extend .fas;
          content: fa-content($fa-var-check-square);
          color: $fe-checkbox__checkbox--checked;
        }
      }
    }
    &[disabled] {
      + .fe-checkbox__checkbox {
        cursor: not-allowed;
        &::before {
          color: $fe-checkbox__checkbox--disabled;
        }
        &::after {
          display: none;
        }
        .fe-checkbox__text {
          color: $fe-color__text-primary--gamma;
        }
      }
    }
  }
}
