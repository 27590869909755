.fe-avatar {
  display: inline-flex;

  &__circle, &__img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    vertical-align: middle;
  }
  &__circle {
    position: relative;
    .fe-avatar__initials {
      display: none;
    }
    &--no-image {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $fe-avatar__no-image-background;
      font-size: 24px;
      font-weight: 600;
      color: $fe-avatar__no-image-text;
      .fe-avatar__img {
        display: none;
      }
      .fe-avatar__initials {
        display: block;
        text-transform: uppercase;
      }
    }
  }
  &__status {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    position: absolute;
    right: 4px;
    bottom: 0;
    background-color: $fe-avatar__status-online;
    border: 1px solid $fe-avatar__status-border;
  }

  &--offline {
    .fe-avatar__img {
      filter: grayscale(100%);
    }
    .fe-avatar__status {
      background-color: $fe-avatar__status-offline;
    }
  }

  &--away {
    .fe-avatar__status {
      background-color: $fe-avatar__status-away;
    }
  }
  &--dnd {
    .fe-avatar__status {
      background-color: $fe-avatar__status-dnd;
    }
  }
  &--small {
    .fe-avatar__circle, .fe-avatar__img {
      width: 24px;
      height: 24px;
    }
    .fe-avatar__status {
      right: -2px;
    }
    .fe-avatar__circle--no-image {
      font-size: 10px;
    }
  }
  &__label {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    &--horizontal {
      flex-direction: row;
      align-items: center;
      .fe-avatar__label {
        margin-left: 8px;
      }
    }
    &--vertical {
      flex-direction: column;
      align-items: center;
      .fe-avatar__label {
        margin-top: 8px;
        align-items: center;
      }
    }
  }
  &__label-name {
    font-size: 12px;
    color: $fe-avatar__label-name-text;
  }
  &__label-description {
    font-size: 9px;
    color: $fe-avatar__label-description-text;
  }
}
