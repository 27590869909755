@import "../utils/variables/components/pill-box";

.fe-pill-box {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  text-transform: capitalize;
  white-space: nowrap;
  border-width: 1px;
  border-style: solid;
  line-height: 1;
  border-radius: $fe-pill-box--border-radius;

  @include box-modal($fe-pill-box--margin, $fe-pill-box--padding, $fe-pill-box--font-size, $fe-pill-box--height, $fe-pill-box--width);
  @include background-varient($fe-pill-box__action-close--background, $fe-pill-box__action-close--border, $fe-pill-box__action-close--text, $fe-pill-box__action-close--background-hover, $fe-pill-box__action-close--border-hover, $fe-pill-box__action-close--text-hover);

  &.with-icon {
    padding: $fe-pill-box--with-icon--padding;

    i {
      padding: $fe-pill-box--icon--padding;
    }
  }

  .value {
    text-transform: none;
  }

  .close {
    color: $fe-pill-box__action-close--icon;
  }
  .add {
    color: $fe-pill-box__action-add--icon;
  }

  &:hover .close {
    color: $fe-pill-box__action-close--icon-hover;
  }

  &.active,
  &:active {
    @include background-state($fe-pill-box__action-close--background-active, $fe-pill-box__action-close--border-active, $fe-pill-box__action-close--text-active);
  }

  &.create {
    @include background-varient($fe-pill-box__action-add--background, $fe-pill-box__action-add--border, $fe-pill-box__action-add--text, $fe-pill-box__action-add--background-hover, $fe-pill-box__action-add--border-hover, $fe-pill-box__action-add--text-hover);
  }

  &:hover .add {
    color: $fe-pill-box__action-add--icon-hover;
  }


  &.active .close, &:active .close {
    color: $fe-pill-box__action-close--icon-active;
  }

  &.active .add, &:active .add {
    color: $fe-pill-box__action-add--icon-hover;
  }

  &.disabled,
  &[disabled] {
    cursor: not-allowed;
    @include background-state($fe-pill-box--disabled, $fe-color__transparent, $fe-pill-box--text--disabled);
    .close,
    .add {
      color: $fe-pill-box--icon--disabled;
    }
  }
}
