.fe-list-notice {
  border-top: 1px solid $fe-color__list-notification-border;
  border-bottom: 1px solid $fe-color__list-notification-border;
  position: relative;
  background-color: $fe-color__notification-background;
  display: flex;
  &__container {
    margin: 8px;
    flex-grow: 1;
  }
  &__indicator {
    width: 4px;
    background-color: $fe-color__list-notification--no-disposition;
  }
  &__label {
    @extend .fe-notice__label;
    line-height: 16px;
  }
  &__message {
    display: flex;
    flex-direction: row;
    line-height: 16px;
  }
  &__text {
    @extend .fe-notice__text;
  }
  &__icon {
    color: $fe-color__list-notification--no-disposition;
    font-size: 16px;
    margin-right: 8px;
  }
  &--success {
    .fe-list-notice__indicator {
      background-color: $fe-color__theme-success--alpha;
    }
    .fe-list-notice__icon {
      color: $fe-color__theme-success--alpha;
    }
  }

  &--error {
    .fe-list-notice__indicator {
      background-color: $fe-color__theme-danger--alpha;
    }
    .fe-list-notice__icon {
      color: $fe-color__theme-danger--alpha;
    }
  }

  &--warning {
    .fe-list-notice__indicator {
      background-color: $fe-color__theme-warn--alpha;
    }
    .fe-list-notice__icon {
      color: $fe-color__theme-warn--alpha;
    }
  }

  &--info {
    .fe-list-notice__indicator {
      background-color: $fe-color__theme-information--alpha;
    }
    .fe-list-notice__icon {
      color: $fe-color__theme-information--alpha;
    }
  }
  &--unread {
    .fe-list-notice__text {
      font-weight: bold;
    }
    background-color: rgba($fe-color__theme-information--alpha, 0.2);
  }
}
