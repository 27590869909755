/* BEGIN Semibold */
@font-face {
  font-family: 'Open Sans';
  @include fontdef($OpenSansPath, $OpenSansVersion, "Semibold");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Source Code Pro';
  src: url("../fonts/Semibold/SourceCodePro-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
/* END Semibold */
