/* BEGIN Regular */
@font-face {
  font-family: 'Open Sans';
  @include fontdef($OpenSansPath, $OpenSansVersion, 'Regular');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Source Code Pro';
  src: url("../fonts/Regular/SourceCodePro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Book';
  src: url("../fonts/Regular/Gotham-Book.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
/* END Regular */
