@import "../utils/variables/components/modal";
@import "../utils/variables/animation";

//This class needs to be applied to body in order to view the modal
.fe-modal-open {
  overflow: hidden;

  .fe-modal {
    overflow-y: auto;
    display: flex;
    opacity: 1;
  }

}

// Container that the modal scrolls within
.fe-modal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1024;
  outline: 0;
  width: 100%;
  background-color: $fe-modal__overlay-background;
  opacity: 0;
  animation: fadeInOverlayMask $fe-modal__animation-duration $fe-modal__animation-timing;
  &__dialog {
    position: relative;
    align-self: center;
    width: auto;
    margin: 0 auto;
    animation: slideInTopModal $fe-modal__animation-duration $fe-modal__animation-timing;
    &--medium, &--large, &--small {
      .fe-modal__content {
        max-height: 90vh;
        .fe-modal__body {
          overflow: auto;
        }
      }
    }
    &--small {
      width: 400px;
    }
    &--medium {
      width: 600px;
    }
    &--large {
      width: 800px;
    }
  }
  &__content {
    position: relative;
    background-color: $fe-modal__body-background;
    box-shadow: 0px 0px 60px 5px $fe-modal__box-shadow-color;
    outline: 0;
    display: flex;
    flex-direction: column;
  }
  &__header {
    padding: 8px 16px;
    background-color: $fe-modal__header-background;
    color: $fe-modal__header-text;
    display: flex;
    align-items: baseline;
    &--with-icon {
      font-size: 24px;
      line-height: 28px;
    }
    .fe-modal-close {
      background: transparent;
      border: none;
      font-size: 24px;
      line-height: 16px;
      margin-left: auto;
      padding: 0;
      color: $fe-modal-close-color;
    }
  }
  &__title {
    margin: 0 8px;
    line-height: 32px;
    font-size: 24px;
    font-weight: normal;
  }
  &__body {
    position: relative;
    margin: 16px 16px 0 16px;
    font-size: 12px;
    &--overflow {
      &:after {
        content: '';
        display: block;
        position: relative; // for older browsers
        position: sticky; // for new-latest browsers
        bottom: 0;
        left: 0;
        right: 0;
        height: 36px;
        background-image: $fe-modal-body--overflow-background;
      }
    }
  }
  &__footer {
    padding: 16px;
    text-align: right;
    .fe-btn {
      margin-right: 16px;
      &:last-child {
        margin-right: 0;
      }
    }
    .fe-component-wrapper {
      .fe-btn {
        margin-right: 16px;
      }
    }
    .fe-component-wrapper:last-child {
      .fe-btn {
        margin-right: 0
      }
    }
  }
}
