.fe-filters {
  border: 1px solid $fe-filters-border;
  background-color: $fe-filters-background;
  min-height: 32px;
  display: flex;
  &__icon {
    margin: 8px;
    color: $fe-color__action-secondary--alpha;
    line-height: 16px;
  }
  &__btns {
    margin-left: auto;
  }
  &__input {
    border: 0;
    background-color: transparent;
    outline: 0;
    flex: 1;
    &::placeholder {
      font-style: italic;
      color: $fe-color__text-primary--gamma;
    }
  }
  &__box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 1;
    > * {
      margin: 4px;
      white-space: nowrap;
      font-size: 12px;
    }
  }
}
