.fe-toggle {
  &__btn {
    outline: 0;
    padding: 0 8px;
    display: inline-block;
    line-height: 25px;
    height: 28px;
    width: 60px;
    position: relative;
    cursor: pointer;
    user-select: none;
    background: $fe-color__action-secondary--alpha;
    border: 2px solid $fe-color__transparent;
    border-radius: 10em;
    transition: all 150ms ease;
    font-weight: bold;
    font-size: 12px;
    text-align: right;
    color: $fe-color__background1--alpha;

    &:before {
      content: "Off";
    }

    &:after {
      content: "";
      display: block;
      width: 24px;
      height: 24px;
      position: absolute;
      border-radius: 50%;
      background: $fe-color__background1--alpha;
      box-shadow: -1px 0 2px 0 $fe-color__box-shadow;
      left: 0;
      top: 0;
    }

    &--sm {
      height: 20px;
      width: 47px;
      line-height: 17px;
      font-size: 10px;

      &:after {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.fe-toggle__checkbox {
  opacity: 0;
  position: absolute;
  &:focus {
    ~ .fe-toggle__btn {
      box-shadow: 0 0 0 4px $fe-color__background1--alpha;
    }
  }
  &:checked + .fe-toggle__btn {
    background: $fe-color__active--alpha;
    text-align: left;

    &:before {
      content: "On";
    }

    &:after {
      left: auto;
      right: 0;
    }
  }
}
